import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Card, Form, Button, Alert } from "react-bootstrap";
import apiAccesses from "../Apis/Accesses";
import HelparAlert from "../Helpars/Alert";
import LayoutProjects from "../Layouts/LayoutProjects";
import AccessCard from "./Card";
import apiAccessModules from "../Apis/AccessModules";
import moment from "moment";
import CustomFieldsIndex from "../CustomFields/Index";
import GeneralStructures from "./GeneralStructures";
import { useAuth } from "../AuthenticationUtility/AuthContext";

const AccessesCreate = () => {
  const params = useParams();
  const { logout } = useAuth();

  const [isLoading, setLoading] = useState(false);
  const [customFieldData, setCustomFieldData] = useState([]);

  const [name, setName] = useState("");
  const [startsAt, setStartsAt] = useState("");
  const [startsAtTime, setStartsAtTime] = useState("");
  const [endsAt, setEndsAt] = useState("");
  const [endsAtTime, setEndsAtTime] = useState("");
  const [identifierValue, setIdentifierValue] = useState("");
  const [accessModuleId, setaccessModuleId] = useState("");
  const [accessModulesList, setAccessModulesList] = useState([]);
  const [accessModulesData, setAccessModulesData] = useState(null);

  const [isSelectAccessModule, setIsSelectAccessModule] = useState(false);
  const [lastSelected, setLastSelected] = useState(null); // Track the last selected ID
  const [isGeneralStructuresAvailable, setGeneralStructures] = useState(false);

  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState("");

  const [validated, setValidated] = useState(false);

  const onChangeName = (event) => {
    const maxCharacterCount = 20;
    if (event.target.value.length <= maxCharacterCount) {
      setName(event.target.value);
    }
  };
  const onChangeStartsAt = (event) => {
    setStartsAt(event.target.value);
  };
  const onChangeStartsAtTime = (event) => {
    setStartsAtTime(event.target.value);
  };
  const onChangeEndsAt = (event) => {
    setEndsAt(event.target.value);
  };
  const onChangeEndsAtTime = (event) => {
    setEndsAtTime(event.target.value);
  };
  const onChangeIdentifierValue = (event) => {
    setIdentifierValue(event.target.value);
  };
  const onChangeAccessModule = (event) => {
    setaccessModuleId(event.target.value);
    if (event.target.value) {
      setIsSelectAccessModule(true);
    } else {
      setIsSelectAccessModule(false);
    }
  };

  const AccessModulesData = async () => {
    if (params.access_model_id === undefined) {
      let response = await apiAccessModules.list(
        logout,
        params.hash_id,
        "",
        1,
        200
      );
      setAccessModulesList(response.records);
      setStatus(response.status);
      setMessage(response.message);
    } else {
      setaccessModuleId(params.access_model_id);
      let response = await apiAccessModules.GetById(
        logout,
        params.hash_id,
        params.access_model_id
      );
      setAccessModulesData(response.data);
    }
  };
  useEffect(() => {
    AccessModulesData();
  }, []);
  const onSubmit = async (event) => {
    setLoading(true);
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      const moduleId = params.access_model_id
        ? params.access_model_id
        : accessModuleId;
      let AccessModulesObj = {
        name: name,
        starts_at:
          startsAt &&
          startsAtTime &&
          new Date(startsAt + " " + startsAtTime).toISOString(),
        ends_at:
          endsAt &&
          endsAtTime &&
          new Date(endsAt + " " + endsAtTime).toISOString(),
        identifier_value: identifierValue,
        identifier_type: "email",
        custom_fields_data: customFieldData,
        general_structures_id: lastSelected,
      };
      let response = await apiAccesses.Create(
        logout,
        moduleId,
        params.hash_id,
        AccessModulesObj
      );
      setStatus(response.status);
      setMessage(response.message);
      setErrors(response.errors);

      if (response.status === "success") {
        event.preventDefault();
        setName("");
        setStartsAt("");
        setStartsAtTime("");
        setEndsAt("");
        setEndsAtTime("");
        setIdentifierValue("");
        setValidated(false);
      }
    }

    setLoading(false);
  };

  const populateDurationType = (type) => {
    if (startsAt) {
      const startDate = moment(startsAt);
      return (
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Ends at</Form.Label>
              {type === "day" ? (
                <div className="fw-bold">
                  {startDate.add(1, "day").format("DD-MM-YYYY")}
                </div>
              ) : type === "month" ? (
                <div className="fw-bold">
                  {startDate.add(1, "months").format("DD-MM-YYYY")}
                </div>
              ) : type === "week" ? (
                <div className="fw-bold">
                  {startDate.add(1, "week").format("DD-MM-YYYY")}
                </div>
              ) : type === "year" ? (
                <div className="fw-bold">
                  {startDate.add(1, "year").format("DD-MM-YYYY")}
                </div>
              ) : (
                ""
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Time</Form.Label>

              <div className="fw-bold">{startsAtTime}</div>
            </Form.Group>
          </Col>
        </Row>
      );
    }
  };
  const LayoutPropsCurrentRootName =
    params.access_model_id !== null &&
    params.access_model_id !== 0 &&
    params.access_model_id !== undefined
      ? accessModulesData && accessModulesData.title + " - Send Access"
      : "Send Access";
  const LayoutPropsRootName =
    params.access_model_id !== null &&
    params.access_model_id !== 0 &&
    params.access_model_id !== undefined
      ? "Access Modules"
      : "Accesses";
  const LayoutPropsRootLink =
    params.access_model_id !== null &&
    params.access_model_id !== 0 &&
    params.access_model_id !== undefined
      ? "/business/projects/access-modules/list/" + params.hash_id
      : "/business/projects/accesses/list/" + params.hash_id;
  return (
    <LayoutProjects
      currentRootName={LayoutPropsCurrentRootName}
      rootName={LayoutPropsRootName}
      rootLink={LayoutPropsRootLink}
    >
      <Row>
        <Col md={8}>
          {params.access_model_id === undefined && (
            <Card className="shadow-sm mb-5 rounded border border-0">
              <Card.Header>Select Access Module</Card.Header>
              <Card.Body className="d-flex">
                {accessModulesList ? (
                  <Form.Select
                    aria-label="Default select example"
                    value={accessModuleId}
                    onChange={onChangeAccessModule}
                    className="mb-3"
                  >
                    <option value="">Open this select menu</option>
                    {accessModulesList &&
                      accessModulesList.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.title}
                        </option>
                      ))}
                  </Form.Select>
                ) : (
                  <Alert color="secondary" className="text-center w-100">
                    <h5 className="mb-3">{message}</h5>
                  </Alert>
                )}
              </Card.Body>
            </Card>
          )}
          {(params.access_model_id === undefined && isSelectAccessModule) ||
          (params.access_model_id !== undefined &&
            isSelectAccessModule === false) ? (
            <Card className="shadow-sm p-3 mb-5 rounded border border-0">
              <Card.Body className="d-flex">
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={onSubmit}
                  className="w-100"
                >
                  <HelparAlert
                    status={status}
                    message={message}
                    errors={errors}
                  ></HelparAlert>
                  <Row>
                    <Col>
                      <GeneralStructures
                        access_module_id={params.access_model_id}
                        setLastSelected={setLastSelected}
                        lastSelected={lastSelected} setGeneralStructures={setGeneralStructures}
                      />
                    </Col>
                  </Row>
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      value={name}
                      onChange={onChangeName}
                      placeholder="Enter name"
                      required
                    />
                    <Form.Text className="text-muted">
                      Enter a maximum of 20 characters
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      name is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  {accessModulesData &&
                    accessModulesData.expiration_type === "date" && (
                      <>
                        <Row>
                          <Col>
                            <Form.Group className="mb-3">
                              <Form.Label>Starts at</Form.Label>
                              <Form.Control
                                value={startsAt}
                                onChange={onChangeStartsAt}
                                type="date"
                                placeholder="Enter user name"
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                Starts at is required
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group className="mb-3">
                              <Form.Label>Time</Form.Label>
                              <Form.Control
                                value={startsAtTime}
                                onChange={onChangeStartsAtTime}
                                type="time"
                                placeholder="Enter user name"
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                Starts at time is required
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        {accessModulesData &&
                        accessModulesData.duration_type === "custom" ? (
                          <Row>
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label>Ends at</Form.Label>
                                <Form.Control
                                  value={endsAt}
                                  onChange={onChangeEndsAt}
                                  placeholder="Enter password"
                                  type="date"
                                  required
                                />
                                <Form.Control.Feedback type="invalid">
                                  Ends at is required
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label>Time</Form.Label>
                                <Form.Control
                                  value={endsAtTime}
                                  onChange={onChangeEndsAtTime}
                                  placeholder="Enter password"
                                  type="time"
                                  required
                                />
                                <Form.Control.Feedback type="invalid">
                                  Ends at time is required
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                        ) : (
                          populateDurationType(
                            accessModulesData && accessModulesData.duration_type
                          )
                        )}
                      </>
                    )}
                  <Form.Group className="mb-4">
                    <Form.Label>Send by Email</Form.Label>
                    <Form.Control
                      value={identifierValue}
                      onChange={onChangeIdentifierValue}
                      placeholder="Enter Email"
                      type="email"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Email is required
                    </Form.Control.Feedback>
                  </Form.Group>

                  <CustomFieldsIndex
                    custom_fields={
                      accessModulesData && accessModulesData.custom_fields
                    }
                    setCustomFieldData={setCustomFieldData}
                  />
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading…" : "Send"}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          ) : (
            ""
          )}
        </Col>
        <Col md={4}>
          <AccessCard
            description={null}
            checkins_maximum={null}
            reader_apps={null}
            starts_at={startsAt + " " + startsAtTime}
            ends_at={endsAt + " " + endsAtTime}
            access_module_id={accessModuleId}
            access_name={name}
          />
        </Col>
      </Row>
    </LayoutProjects>
  );
};
export default AccessesCreate;
