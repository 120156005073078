 import ApiBase from './Base'

const url = "api/accesses/"

export default {
    async list(logout,hash_id, searchText = '' , dateFrom = '', dateTo = '', reader_app_id = '', reader_id = '', access_module_id = '', form_id = '', page, per) {
        try {
            const response = await ApiBase.apiBase(url + 'list/' + hash_id + '?search_text=' + searchText + '&date_from=' + dateFrom + '&date_to=' + dateTo +
                '&reader_app_id=' + reader_app_id +'&reader_id=' + reader_id + '&access_module_id=' + access_module_id + '&form_id=' + form_id + '&page=' + page + '&per=' + per, 'GET',logout)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async ListParent(logout,hash_id, searchText = '' , dateFrom = '', dateTo = '', reader_app_id = '', reader_id = '', access_module_id = '', form_id = '', page, per) {
        try {
            const response = await ApiBase.apiBase(url + 'list_parent/' + hash_id + '?search_text=' + searchText + '&date_from=' + dateFrom + '&date_to=' + dateTo +
                '&reader_app_id=' + reader_app_id +'&reader_id=' + reader_id + '&access_module_id=' + access_module_id + '&form_id=' + form_id + '&page=' + page + '&per=' + per, 'GET',logout)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async Create(logout,access_module_id, hash_id, body) {
        try {
             const urls = url + 'create/' + access_module_id + '/' + hash_id;
            const response = await ApiBase.apiBase(urls, 'POST',logout, body)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async Delete(logout,id, hash_id) {
        try {
            const urls = url + 'delete/' + id + '/' + hash_id;
            const response = await ApiBase.apiBase(urls, 'DELETE',logout)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async CreateByForm(logout,form_hash_id , body) {
        try {
            const urls = url + 'form/' + form_hash_id + '/create';
            const response = await ApiBase.apiBase(urls, 'POST',logout, body,false,true)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
}
