import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "react-bootstrap";

// Create the root container for React
const root = ReactDOM.createRoot(document.getElementById("root"));

// Render the application
root.render(
  <ThemeProvider>
    <App />
  </ThemeProvider>
);

// For performance monitoring (optional)
reportWebVitals();
