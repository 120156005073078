import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Card, Form, Button, Row, Col, Spinner } from "react-bootstrap";
import apiAccessModuleForms from "../Apis/AccessModuleForms";
import apiAccessModules from "../Apis/AccessModules";
import HelparAlert from "../Helpars/Alert";
import LayoutProjects from "../Layouts/LayoutProjects";
import moment from "moment";
import FormPreview from "./Preview";
import { useAuth } from "../AuthenticationUtility/AuthContext";
import CustomFieldsCreate from "../CustomFields/Create";

const FormUpdate = () => {
  const { logout } = useAuth();
  const params = useParams();
  const alertRef = useRef(null); // Create a ref for the alert component

  const [validated, setValidated] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isPageLoading, setPageLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState("");
  const [coverUrl, setCoverUrl] = useState(null);
  const [cover, setCover] = useState(null);
  const [accessModulesData, setAccessModulesData] = useState(null);
  const [customFieldData, setCustomFieldData] = useState([]);
  const [accessModuleFormsData, setAccessModuleFormsData] = useState({
    title: "",
    description: "",
    accessMaximum: "",
    startAt: "",
    startAtTime: "",
    endAt: "",
    endAtTime: "",
    accessActions: "",
    accessStartAt: "",
    accessStartAtTime: "",
    accessEndAt: "",
    accessEndAtTime: "",
    customFields: [],
  });

  useEffect(() => {
    (async () => {
      try {
        await populateFormsData();
        await populateAccessModuleData();
      } catch (error) {
        setErrors(error.message || "Error loading data");
      } finally {
        setPageLoading(false);
      }
    })();
  }, []);

  const populateFormsData = async () => {
    const response = await apiAccessModuleForms.GetById(
      logout,
      params.hash_id,
      params.id
    );

    const {
      title = "",
      description = "",
      access_maximum = "",
      start_at,
      end_at,
      access_actions = "",
      access_start_at,
      access_end_at,
      custom_fields = [],
      cover_url,
    } = response.data || {};

    setAccessModuleFormsData({
      title,
      description,
      accessMaximum: access_maximum,
      startAt: start_at ? moment(start_at).format("YYYY-MM-DD") : "",
      startAtTime: start_at ? moment(start_at).format("HH:mm") : "",
      endAt: end_at ? moment(end_at).format("YYYY-MM-DD") : "",
      endAtTime: end_at ? moment(end_at).format("HH:mm") : "",
      accessActions: access_actions,
      accessStartAt: access_start_at
        ? moment(access_start_at).format("YYYY-MM-DD")
        : "",
      accessStartAtTime: access_start_at
        ? moment(access_start_at).format("HH:mm")
        : "",
      accessEndAt: access_end_at
        ? moment(access_end_at).format("YYYY-MM-DD")
        : "",
      accessEndAtTime: access_end_at
        ? moment(access_end_at).format("HH:mm")
        : "",
      customFields: custom_fields,
    });

    setCustomFieldData(custom_fields || []);
    setCoverUrl(cover_url || null);
  };

  const populateAccessModuleData = async () => {
    const response = await apiAccessModules.GetById(
      logout,
      params.hash_id,
      params.access_model_id
    );
    setAccessModulesData(response.data);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAccessModuleFormsData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setCover(file);
    setCoverUrl(file ? URL.createObjectURL(file) : null);
  };

  const convertToISOString = (date, time) => {
    if (!date || !time) return null;
    return new Date(`${date}T${time}`).toISOString();
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("title", accessModuleFormsData.title);
      formData.append("description", accessModuleFormsData.description);
      formData.append("access_maximum", accessModuleFormsData.accessMaximum);
      formData.append("is_active", true);
      formData.append("access_actions", accessModuleFormsData.accessActions);

      formData.append(
        "start_at",
        convertToISOString(
          accessModuleFormsData.startAt,
          accessModuleFormsData.startAtTime
        )
      );
      formData.append(
        "end_at",
        convertToISOString(
          accessModuleFormsData.endAt,
          accessModuleFormsData.endAtTime
        )
      );
      formData.append("cover_file", cover);

      formData.append(
        "access_start_at",
        convertToISOString(
          accessModuleFormsData.accessStartAt,
          accessModuleFormsData.accessStartAtTime
        )
      );
      formData.append(
        "access_end_at",
        convertToISOString(
          accessModuleFormsData.accessEndAt,
          accessModuleFormsData.accessEndAtTime
        )
      );
      formData.append("custom_fields", JSON.stringify(customFieldData));

      const response = await apiAccessModuleForms.Update(
        logout,
        params.id,
        params.hash_id,
        formData
      );

      setStatus(response.status);
      setMessage(response.message);
      setErrors(response.errors);
      // Scroll to the alert element after submission
      if (alertRef.current) {
        alertRef.current.scrollIntoView({ behavior: "smooth" });
      }
    } catch (error) {
      setErrors(error.message || "An error occurred during submission.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <LayoutProjects
      rootName="Access Modules Form"
      rootLink={"/business/projects/access-modules/list/" + params.hash_id}
      currentRootName="Update"
    >
      <Row>
        <Col md={7}>
          <Card className="shadow-sm mb-5 rounded border border-0">
            <Card.Header>Access Module</Card.Header>
            <Card.Body>
              {accessModulesData && (
                <div>
                  <>
                    {accessModulesData.description && (
                      <Row className="mb-3">
                        <Col>
                          <strong>Description</strong>
                          <div> {accessModulesData.description}</div>
                        </Col>
                      </Row>
                    )}
                  </>
                  <>
                    <Row className="mb-3">
                      <Col>
                        <strong>Expiration Type </strong>
                        <div>Date </div>
                      </Col>
                      {accessModulesData.checkins_maximum && (
                        <Col>
                          <strong>Check-ins </strong>
                          <div>
                            {accessModulesData.checkins_maximum} maximum
                          </div>
                        </Col>
                      )}
                    </Row>
                  </>
                  {accessModulesData &&
                    accessModulesData.reader_apps &&
                    accessModulesData.reader_apps.map((item) => (
                      <Row key={item.id} className="mb-3">
                        <Col>
                          <strong>Knto Mobile Readers</strong>
                          <Row>
                            <Col
                              key={item.id}
                              lg={3}
                              className="border p-2 m-1"
                            >
                              <span>{item.name}</span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ))}
                  {accessModulesData &&
                    accessModulesData.readers &&
                    accessModulesData.readers.map((item) => (
                      <Row key={item.id} className="mb-3">
                        <Col>
                          <strong>Knto Readers</strong>
                          <Row>
                            <Col
                              key={item.id}
                              lg={3}
                              className="border p-2 m-1"
                            >
                              <span>{item.name}</span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ))}
                </div>
              )}
            </Card.Body>
          </Card>
          <Form
            noValidate
            validated={validated}
            onSubmit={onSubmit}
             
          >
            <Card className="shadow-sm mb-5 rounded border border-0">
              <Card.Header>Form settings</Card.Header>
              <Card.Body className="d-flex">
                <Form noValidate validated={validated} className="w-100">
                  {/* Alert component with ref */}
                  <div ref={alertRef}>
                    <HelparAlert
                      status={status}
                      message={message}
                      errors={errors}
                    />
                  </div>
                  <Form.Group className="mb-3">
                    <Form.Label>Cover</Form.Label>
                    <Form.Control type="file" onChange={handleImageUpload} />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      name="title"
                      value={accessModuleFormsData.title}
                      onChange={handleInputChange}
                      placeholder="Enter title"
                      required
                    />
                    <Form.Text className="text-muted">
                      Enter a maximum of 150 characters
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      Form Title is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      name="description"
                      as="textarea"
                      rows={3}
                      value={accessModuleFormsData.description}
                      onChange={handleInputChange}
                      placeholder="Enter description"
                      required
                    />
                    <Form.Text className="text-muted">
                      Enter a maximum of 500 characters
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      Form Description is required
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Starts at</Form.Label>
                        <Form.Control
                          name="startAt"
                          value={accessModuleFormsData.startAt}
                          onChange={handleInputChange}
                          type="date"
                          placeholder="Enter user name"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Form Starts at is required
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Time</Form.Label>
                        <Form.Control
                          name="startAtTime"
                          value={accessModuleFormsData.startAtTime}
                          onChange={handleInputChange}
                          type="time"
                          placeholder="Enter user name"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Starts at time is required
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Form Ends at</Form.Label>
                        <Form.Control
                          name="endAt"
                          value={accessModuleFormsData.endAt}
                          onChange={handleInputChange}
                          placeholder="Enter password"
                          type="date"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Form Ends at is required
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Time</Form.Label>
                        <Form.Control
                          name="endAtTime"
                          value={accessModuleFormsData.endAtTime}
                          onChange={handleInputChange}
                          placeholder="Enter password"
                          type="time"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Ends at time is required
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>

            <Card className="shadow-sm mb-5 rounded border border-0">
              <Card.Header>Access settings</Card.Header>
              <Card.Body className="d-flex">
                <Form noValidate validated={validated} className="w-100">
                  <Form.Group className="mb-3">
                    <Form.Label>Access Actions</Form.Label>
                    <div>
                      <Form.Check
                        inline
                        name="accessActions"
                        label="Auto send access"
                        type="radio"
                        checked={
                          accessModuleFormsData.accessActions === "auto_send"
                        }
                        value="auto_send"
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Check
                        inline
                        name="accessActions"
                        label="Access approval"
                        type="radio"
                        checked={
                          accessModuleFormsData.accessActions === "approval"
                        }
                        value="approval"
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Access Actions is required
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Accesses limited</Form.Label>
                    <Form.Control
                      name="accessMaximum"
                      value={accessModuleFormsData.accessMaximum}
                      onChange={handleInputChange}
                      placeholder="Enter Access Maximum"
                      type="number"
                    />
                    <Form.Text className="text-muted"></Form.Text>
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Access Starts at</Form.Label>
                        <Form.Control
                          name="accessStartAt"
                          value={accessModuleFormsData.accessStartAt}
                          onChange={handleInputChange}
                          type="date"
                          placeholder="Enter user name"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Starts at is required
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Time</Form.Label>
                        <Form.Control
                          name="accessStartAtTime"
                          value={accessModuleFormsData.accessStartAtTime}
                          onChange={handleInputChange}
                          type="time"
                          placeholder="Enter user name"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Starts at time is required
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Access Ends at</Form.Label>
                        <Form.Control
                          name="accessEndAt"
                          value={accessModuleFormsData.accessEndAt}
                          onChange={handleInputChange}
                          placeholder="Enter password"
                          type="date"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Ends at is required
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Time</Form.Label>
                        <Form.Control
                          name="accessEndAtTime"
                          value={accessModuleFormsData.accessEndAtTime}
                          onChange={handleInputChange}
                          placeholder="Enter password"
                          type="time"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Ends at time is required
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
            <Card className="shadow-sm mb-5 rounded border border-0">
              <Card.Header>Custom Field</Card.Header>
              <Card.Body className="d-flex p-3">
                <Form noValidate validated={validated} className="w-100">
                  <CustomFieldsCreate
                    customFieldData={customFieldData}
                    setCustomFieldData={setCustomFieldData}
                  />
                </Form>
              </Card.Body>
              <Card.Footer>
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  disabled={isLoading}
                >
                  {isLoading ? "Loading…" : "Submit"}
                </Button>
              </Card.Footer>
            </Card>
          </Form>
        </Col>
        <Col md={5}>
          <div className="sticky-preview">
            <FormPreview
              cover_url={coverUrl}
              title={accessModuleFormsData.title}
              description={accessModuleFormsData.description}
            />
          </div>
        </Col>
      </Row>
    </LayoutProjects>
  );
};
export default FormUpdate;
