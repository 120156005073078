import React, { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import apiAccounts from "../Apis/Accounts";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../AuthenticationUtility/AuthContext";
import HelparAlert from "../Helpars/Alert"; // Importing HelparAlert component

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [alert, setAlert] = useState({
    status: null,
    message: "",
    errors: [],
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    let LoginObj = {
      email: email,
      password: password,
    };
    let response = await apiAccounts.Login(LoginObj);
    if (response && response.status === 401) {
      setAlert((prevAlert) => ({
        ...prevAlert,
        status: "error",
        message: "Invalid email or password",
      }));
    } else if (response && response.status === "success") {
      login(
        response.data.access_token,
        response.data.refresh_token,
        rememberMe
      );
      navigate("/");
    }else if (response && response.status === "not_confirm")  {
      navigate("/account/resend_email_confirmation");
    }
    else    {
      setAlert((prevAlert) => ({
        ...prevAlert,
        status: response.status,
        message: response.message,
        errors: response.errors,
      }));
    }
  };

  return (
    <Container>
      <h2 className="mt-3">Login</h2>
      <h5 className="mb-5">Use a local account to login.</h5>

      <HelparAlert
        status={alert.status}
        message={alert.message}
        errors={alert.errors}
      />
      <Form onSubmit={handleLogin}>
        <Form.Group className="mb-3">
          <Form.Control
            type="email"
            size="lg"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            type="password"
            placeholder="Password"
            size="lg"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Check
            className="mb-3 d-inline-block"
            type="checkbox"
            label="Remember me"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Login
        </Button>
      </Form>
      <p className="mt-3">
        <Link to="/identity/account/forgotpassword" className="text-decoration-none">
          Forgot your password?
        </Link>
        .
      </p>
      <p className="mt-3">
        <Link to="/account/register" className="text-decoration-none">
          Register as a new user
        </Link>
        .
      </p>
    </Container>
  );
};

export default Login;
