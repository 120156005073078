import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  Table,
  Modal,
  Alert,
  Badge,
  ButtonGroup,
} from "react-bootstrap";
import PageLoading from "../Helpars/PageLoading";
import HelparPagination from "../Helpars/Pagination";
import apiAccessRequests from "../Apis/AccessRequests";
import LayoutProjects from "../Layouts/LayoutProjects";
import HelparAlert from "../Helpars/Alert";
import moment from "moment";
import { useAuth } from "../AuthenticationUtility/AuthContext";

const AccessesRequestsList = () => {
  const { logout } = useAuth();
  const params = useParams();
  const [accessesList, setAccessesList] = useState([]);
  const [statusList, setStatusList] = useState("");
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState("");
  const [messageList, setMessageList] = useState("");
  const [requestStatusTitle, setRequestStatusTitle] = useState("");
  const [accessDetailsModalShow, setAccessDetailsModalShow] = useState(false);
  const [accessDetailsDate, setAccessDetailsDate] = useState(null);
  const [listStatus, setListStatus] = useState("pending");
  const [listActions, setListActions] = useState("submit");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryDateFrom, setSearchQueryDateFrom] = useState("");
  const [searchQueryDateTo, setSearchQueryDateTo] = useState("");
  const [isPageLoading, setPageLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); // Current page starts at 1
  const [pageCount, setPageCount] = useState(null); // Current page starts at 1
  const itemsPerPage = 20; // Number of items to display per page
  const [recordsPerPage, setRecordsPerPage] = useState(null); // Current page starts at 1

  const openAccessDetailsModal = (accessDetails) => {
    setAccessDetailsModalShow(true);
    setAccessDetailsDate(accessDetails);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    ListAccesses(listStatus, listActions, page);
  };
  const onChangeQuery = (e) => {
    setSearchQuery(e.target.value);
  };
  const onChangeQueryDateFrom = (e) => {
    setSearchQueryDateFrom(e.target.value);
  };
  const onChangeQueryDateTo = (e) => {
    setSearchQueryDateTo(e.target.value);
  };
  useEffect(() => {
    ListAccesses("pending", "submit", 1);
  }, []);
  const ListAccesses = async (accessStatus, accessActions, page) => {
    let response = await apiAccessRequests.list(
      logout,
      params.hash_id,
      accessActions,
      accessStatus,
      searchQuery,
      searchQueryDateFrom,
      searchQueryDateTo,
      params.form_id,
      page,
      itemsPerPage
    );
    setAccessesList(response.records);
    setStatusList(response.status);
    setMessageList(response.message);
    setPageCount(Math.ceil(response.total / itemsPerPage));
    setPageLoading(false);
    setRecordsPerPage(response.records_per_page);
    if (accessStatus === "pending") {
      setRequestStatusTitle(accessStatus);
    } else {
      setRequestStatusTitle(accessActions);
    }
  };
  const handleAccessActions = async (id, action) => {
    let response = await apiAccessRequests.Actions(
      logout,
      id,
      action,
      params.hash_id
    );
    setStatus(response.status);
    setMessage(response.message);
    setErrors(response.errors);
    let pagecurrent = currentPage;
    if (pageCount > 1) {
      if (recordsPerPage === 1) {
        pagecurrent = currentPage - 1;
      }
    }
    handlePageChange(pagecurrent);
    setAccessDetailsModalShow(false);
  };
  const handleSearch = () => {
    ListAccesses(listStatus, listActions, 1);
  };

  const handleListStatusAndActions = (status, actions) => {
    ListAccesses(status, actions, 1);
    setListStatus(status);
    setListActions(actions);
  };

  function DateFormat(date) {
    return <>{moment(date).format("DD-MM-YYYY")}</>;
  }

  return (
    <LayoutProjects rootName="Accesses Requests">
      <>
        <div className="text-center mb-4 bg-body-secondary py-2">
          <ButtonGroup size="md">
            <Button
              variant="light"
              onClick={() => handleListStatusAndActions("pending", "submit")}
            >
              Pending
            </Button>
            <Button
              variant="light"
              onClick={() => handleListStatusAndActions("closed", "accepted")}
            >
              Accepted
            </Button>
            <Button
              variant="light"
              onClick={() => handleListStatusAndActions("closed", "rejected")}
            >
              Rejected
            </Button>
          </ButtonGroup>
        </div>
        <h5 className="mb-3">
          Access Requests{" "}
          <Badge
            bg={
              requestStatusTitle === "pending"
                ? "secondary"
                : requestStatusTitle === "accepted"
                ? "success"
                : "danger"
            }
          >
            {requestStatusTitle}
          </Badge>{" "}
        </h5>
        <HelparAlert
          status={status}
          message={message}
          errors={errors}
        ></HelparAlert>
        <Row>
          <Col md={2} className="mb-3">
            <Form.Control
              placeholder="Access Name"
              aria-label="Access Name"
              aria-describedby="Access Name"
              value={searchQuery}
              onChange={onChangeQuery}
              type="search"
            />
          </Col>
          <Col md={3} className="mb-3">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">From</InputGroup.Text>
              <Form.Control
                placeholder="Logged from"
                aria-label="Logged from"
                aria-describedby="Logged from"
                value={searchQueryDateFrom}
                onChange={onChangeQueryDateFrom}
                type="datetime-local"
              />
            </InputGroup>
          </Col>
          <Col md={3} className="mb-3">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">To</InputGroup.Text>
              <Form.Control
                placeholder="Logged from"
                aria-label="Logged from"
                aria-describedby="Logged from"
                value={searchQueryDateTo}
                onChange={onChangeQueryDateTo}
                type="datetime-local"
              />
            </InputGroup>
          </Col>
          <Col md={2} className="mb-3">
            <Button variant="primary" id="button-addon2" onClick={handleSearch}>
              Search
            </Button>
          </Col>
        </Row>
        {isPageLoading ? (
          <PageLoading />
        ) : (
          <>
            {statusList === "success" ? (
              <>
                <Table responsive="sm" striped="columns">
                  <thead>
                    <tr>
                      <th>Access Name</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th>Access Module</th>
                      <th>Form</th>
                      <th>more details ...</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accessesList.map((item) => (
                      <tr key={item.id}>
                        <td>{item.name}</td>
                        <td>{item.type}</td>
                        <td>
                          {item.expiration_type === "date" ? (
                            new Date(item.ends_at) <= new Date() ? (
                              <Badge bg="danger">Date Expired</Badge>
                            ) : item.checkins_maximum !== null &&
                              item.checkins_maximum <= item.checkins_count ? (
                              <Badge bg="danger">Check-ins Expired</Badge>
                            ) : (
                              <Badge bg="success">Date Exist</Badge>
                            )
                          ) : (
                            <Badge bg="success">Date Exist</Badge>
                          )}
                        </td>
                        <td>{item.access_module_title}</td>
                        <td>{item.form && item.form.title}</td>
                        <td>
                          <Button
                            className="btn-sm"
                            onClick={() => openAccessDetailsModal(item)}
                          >
                            View details
                          </Button>
                        </td>
                        <td>
                          {item.status === "pending" ? (
                            <>
                              <Button
                                variant="success"
                                size="sm"
                                className="me-3"
                                onClick={() =>
                                  handleAccessActions(item.id, "accepted")
                                }
                              >
                                Accept
                              </Button>
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() =>
                                  handleAccessActions(item.id, "rejected")
                                }
                              >
                                reject
                              </Button>
                            </>
                          ) : item.actions === "accepted" ? (
                            <Badge bg="success"> Accepted </Badge>
                          ) : item.actions === "rejected" ? (
                            <Badge bg="danger"> Rejected</Badge>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                {/* Render the pagination component */}
                <HelparPagination
                  pageCount={pageCount}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              </>
            ) : (
              <Alert color="secondary" className="text-center">
                <h5 className="mb-3">{messageList}</h5>
              </Alert>
            )}
            <Modal
              show={accessDetailsModalShow}
              onHide={() => setAccessDetailsModalShow(false)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title className="text-primary">
                  Access Details
                </Modal.Title>
              </Modal.Header>
              {accessDetailsDate && (
                <>
                  <Modal.Body>
                  <Row className="mb-3">
                      <Col>
                        <div className="fw-bold">Type</div>
                        <div>{accessDetailsDate.type}</div>
                      </Col>
                      
                    </Row>
                    {/* Render General Structure */}
                    {(() => {
                      try {
                        const generalStructure = JSON.parse(
                          accessDetailsDate.general_structure
                        );

                        if (
                          Array.isArray(generalStructure) &&
                          generalStructure.length > 0
                        ) {
                          return (
                            <Row className="mb-3">
                              {generalStructure.map((item, index) => (
                                <Col key={index} className="mb-3">
                                  <div className="fw-bold">
                                    {item.label || "Unnamed Field"}
                                  </div>
                                  <div>{item.value || "No value provided"}</div>
                                </Col>
                              ))}
                            </Row>
                          );
                        } else {
                          // return (
                          //   <div className="text-danger">
                          //     No general structure available.
                          //   </div>
                          // );
                        }
                      } catch (error) {
                        console.error(
                          "Error parsing general_structure:",
                          error
                        );
                        // return (
                        //   <div className="text-danger">
                        //     Failed to load general structure.
                        //   </div>
                        // );
                      }
                    })()}

                    {/* Render Other Meta Data */}
                    {(() => {
                      try {
                        const metaData = JSON.parse(
                          accessDetailsDate.meta_data
                        );
                        const customFields = metaData.custom_fields;

                        if (
                          customFields &&
                          Array.isArray(customFields) &&
                          customFields.length > 0
                        ) {
                          return (
                            <Row className="mb-3">
                              {customFields.map((field, index) => (
                                <Col key={index} className="mb-3">
                                  <div className="fw-bold">
                                    {field.label || "Unnamed Field"}
                                  </div>
                                  <div>
                                    {field.data_type === "file" &&
                                    field.value ? (
                                      field.value.match(
                                        /\.(jpeg|jpg|png|webp|gif)$/i
                                      ) ? (
                                        <img
                                          src={field.value}
                                          alt={field.label || "Uploaded File"}
                                          style={{
                                            maxWidth: "100%",
                                            maxHeight: "200px",
                                            borderRadius: "5px",
                                          }}
                                        />
                                      ) : (
                                        <a
                                          href={field.value}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          View File
                                        </a>
                                      )
                                    ) : (
                                      field.value || "No value provided"
                                    )}
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          );
                        } else {
                          return (
                            <div className="text-danger">
                              No custom fields available.
                            </div>
                          );
                        }
                      } catch (error) {
                        console.error("Error parsing meta_data:", error);
                        // return (
                        //   <div className="text-danger">
                        //     Failed to load custom fields.
                        //   </div>
                        // );
                      }
                    })()}
                    {accessDetailsDate.type=="clone" && (
                      <>
                      <Row className="mb-3">
                      <Col>
                        <div className="fw-bold">Relationship</div>
                        <div>{accessDetailsDate.access_relationship}</div>
                      </Col>
                       
                    </Row>
                      
                      <Row className="mb-3">
                        <Col>
                          <div className="fw-bold">Clone Identity Front</div>
                          {accessDetailsDate.clone_identity_front_id ? (
                            <img
                              src={accessDetailsDate.clone_identity_front_id}
                              alt="Clone Identity Front"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "200px",
                                borderRadius: "5px",
                              }}
                            />
                          ) : (
                            "No Front Clone Available"
                          )}
                        </Col>
                        <Col>
                          <div className="fw-bold">Clone Identity Back</div>
                          {accessDetailsDate.clone_identity_back_id ? (
                            <img
                              src={accessDetailsDate.clone_identity_back_id}
                              alt="Clone Identity Back"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "200px",
                                borderRadius: "5px",
                              }}
                            />
                          ) : (
                            "No Back Clone Available"
                          )}
                        </Col>
                      </Row>
                      </>
                    )}
                    {/* Access Details */}
                    <Row className="mb-3">
                      <Col>
                        <div className="fw-bold">Access Name</div>
                        <div>{accessDetailsDate.name}</div>
                      </Col>
                      <Col>
                        <div className="fw-bold">Access Module</div>
                        <div>{accessDetailsDate.access_module_title}</div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <div className="fw-bold">Access Start At</div>
                        <div>{DateFormat(accessDetailsDate.starts_at)}</div>
                      </Col>
                      <Col>
                        <div className="fw-bold">Access End At</div>
                        <div>{DateFormat(accessDetailsDate.ends_at)}</div>
                      </Col>
                    </Row>
                    {accessDetailsDate.form&&
                    <Row className="mb-3">
                      <Col>
                        <div className="fw-bold">Form Name</div>
                        <div>{accessDetailsDate.form?.title || ""}</div>
                      </Col>
                    </Row>
                    }
                  </Modal.Body>
                  <Modal.Footer>
                    {accessDetailsDate.status === "pending" ? (
                      <>
                        <Button
                          variant="success"
                          size="sm"
                          className="me-3"
                          onClick={() =>
                            handleAccessActions(
                              accessDetailsDate.id,
                              "accepted"
                            )
                          }
                        >
                          Accept
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() =>
                            handleAccessActions(
                              accessDetailsDate.id,
                              "rejected"
                            )
                          }
                        >
                          Reject
                        </Button>
                      </>
                    ) : accessDetailsDate.actions === "accepted" ? (
                      <Badge bg="success">Accepted</Badge>
                    ) : accessDetailsDate.actions === "rejected" ? (
                      <Badge bg="danger">Rejected</Badge>
                    ) : (
                      ""
                    )}
                  </Modal.Footer>
                </>
              )}
            </Modal>
          </>
        )}
      </>
    </LayoutProjects>
  );
};
export default AccessesRequestsList;
