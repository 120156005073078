import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  Button,
  Dropdown,
  InputGroup,
  Form,
  ProgressBar,
  Badge,
  Alert,
} from "react-bootstrap";
import apiAccessModules from "../Apis/AccessModules";
import LayoutProjects from "../Layouts/LayoutProjects";
import * as Icon from "react-bootstrap-icons";
import PageLoading from "../Helpars/PageLoading";
import HelparPagination from "../Helpars/Pagination";
import DeleteConfirmationDialog from "../Helpars/DeleteConfirmationDialog";
import HelparAlert from "../Helpars/Alert";
import moment from "moment";
import { useAuth } from "../AuthenticationUtility/AuthContext";

const AccessModulesList = () => {
  const { logout } = useAuth();
  const params = useParams();
  const [accessModulesList, setAccessModulesList] = useState([]);
  const [status, setStatus] = useState("");
  const [statusList, setStatusList] = useState("");
  const [message, setMessage] = useState("");
  const [messageList, setMessageList] = useState("");
  const [errors, setErrors] = useState("");
  const [isPageLoading, setPageLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(null);
  const [recordsPerPage, setRecordsPerPage] = useState(null);
  const itemsPerPage = 20;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [accessId, setAccessId] = useState(false);
  const [isDeleteLoading, setDeleteLoading] = useState(false);

  // Memoized version of ListAccessModules to avoid re-creation on every render
  const ListAccessModules = useCallback(
    async (page = 1, searchQueryData = "") => {
      setPageLoading(true);
      const response = await apiAccessModules.list(
        logout,
        params.hash_id,
        searchQueryData,
        page,
        itemsPerPage
      );
      setAccessModulesList(response.records);
      setStatusList(response.status);
      setMessageList(response.message);
      setPageCount(Math.ceil(response.total / itemsPerPage));
      setRecordsPerPage(response.records_per_page);
      setPageLoading(false);
    },
    [logout, params.hash_id, itemsPerPage]
  );

  useEffect(() => {
    ListAccessModules(1);
  }, [ListAccessModules]);

  const handleDeleteClick = (id) => {
    setIsDeleteDialogOpen(true);
    setAccessId(id);
  };

  const handleConfirmDelete = async (id) => {
    setDeleteLoading(true);
    const response = await apiAccessModules.Delete(logout, id, params.hash_id);
    setStatus(response.status);
    setMessage(response.message);
    setErrors(response.errors);
    const pageCurrent = pageCount > 1 && recordsPerPage === 1 ? currentPage - 1 : currentPage;
    handlePageChange(pageCurrent);
    setIsDeleteDialogOpen(false);
    setDeleteLoading(false);
  };

  const handleCloseDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    ListAccessModules(page);
  };

  const onChangeQuery = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value === "") {
      ListAccessModules(1);
    }
  };

  const handleSearch = () => {
    ListAccessModules(1, searchQuery);
  };

  const subscriptionDatestatus = (start, end) => {
    const currentDate = new Date();
    if (new Date(start) > currentDate) {
      return <Badge bg="secondary">Not Started</Badge>;
    } else if (new Date(end) < currentDate) {
      return <Badge bg="danger">Expired</Badge>;
    } else if (new Date(start) <= currentDate && new Date(end) >= currentDate) {
      return <Badge bg="success">Active</Badge>;
    }
  };

  return (
    <LayoutProjects rootName="Access Modules">
      <Row>
        <Col md={4} className="mb-3">
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Title"
              aria-label="Title"
              aria-describedby="Title"
              value={searchQuery}
              onChange={onChangeQuery}
              type="search"
            />
            <Button variant="primary" id="button-addon2" onClick={handleSearch}>
              Search
            </Button>
          </InputGroup>
        </Col>
      </Row>
      {isPageLoading ? (
        <PageLoading />
      ) : (
        <>
          {statusList === "success" ? (
            <>
              <HelparAlert status={status} message={message} errors={errors} />
              <Row>
                {accessModulesList.map((item) => (
                  <Col md={6} className="mb-3" key={item.id}>
                    <Card className="shadow-sm" border="dark">
                      <Card.Body>
                        <div className="d-flex justify-content-between align-items-start flex-wrap">
                          <div className="d-flex">
                            <div
                              style={{ width: 60, height: 60 }}
                              className="d-flex bg-primary border-primary fs-5 text-white fw-bold rounded align-content-center align-items-center justify-content-center me-2 mb-3"
                            >
                              <Icon.LayersFill size={40} />
                            </div>
                            <Card.Title className="fs-5 text-gray-800 text-hover-primary mb-3">
                              {item.title.charAt(0).toUpperCase() +
                                item.title.slice(1)}
                            </Card.Title>
                          </div>
                          <div className="ms-2">
                            {item.business_access_module_quotas ? (
                              <>
                                <div className="hstack gap-3 pb-2">
                                  <div style={{ fontSize: 12 }}>
                                    Start{" "}
                                    <span className="fw-bold text-primary">
                                      {moment(
                                        item.business_access_module_quotas
                                          .starts_at
                                      ).format("DD-MM-YYYY")}
                                    </span>
                                  </div>
                                  <div style={{ fontSize: 12 }}>
                                    End{" "}
                                    <span className="fw-bold text-primary">
                                      {moment(
                                        item.business_access_module_quotas
                                          .ends_at
                                      ).format("DD-MM-YYYY")}
                                    </span>
                                  </div>
                                  <div style={{ fontSize: 12 }}>
                                    {subscriptionDatestatus(
                                      item.business_access_module_quotas
                                        .starts_at,
                                      item.business_access_module_quotas.ends_at
                                    )}
                                  </div>
                                </div>
                                <div className="hstack gap-3">
                                  <div className="p-2 border">
                                    <div className="fs-6 fw-bold text-primary">
                                      {
                                        item.business_access_module_quotas
                                          .user_maximum
                                      }
                                    </div>{" "}
                                    Users
                                  </div>
                                  <div className="p-2 border">
                                    <div className="fs-6 fw-bold text-primary">
                                      {item.business_access_module_quotas
                                        .user_count
                                        ? item.business_access_module_quotas
                                            .user_count
                                        : 0}
                                    </div>{" "}
                                    Use
                                  </div>
                                  <div className="p-2 border">
                                    <div className="fs-6 fw-bold text-primary">
                                      {item.business_access_module_quotas
                                        .user_maximum
                                        ? item.business_access_module_quotas
                                            .user_maximum -
                                          item.business_access_module_quotas
                                            .user_count
                                        : 0}
                                    </div>{" "}
                                    Remainder
                                  </div>
                                </div>
                                <ProgressBar
                                  now={
                                    (item.business_access_module_quotas
                                      .user_count /
                                      item.business_access_module_quotas
                                        .user_maximum) *
                                    100
                                  }
                                  label={`${parseInt(
                                    (item.business_access_module_quotas
                                      .user_count /
                                      item.business_access_module_quotas
                                        .user_maximum) *
                                      100
                                  )}%`}
                                  className="mt-3"
                                />
                              </>
                            ) : (
                              <div className="hstack gap-3">
                                <div className="p-2 border">
                                  <div className="fs-6 fw-bold text-primary">
                                    Unlimited
                                  </div>{" "}
                                  Users
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div>
                        <div class="row my-3" style={{ fontSize: 14 }}>
                                  <div class="col-md-4 mb-2">
                                    <Icon.Calendar2RangeFill
                                      size={15}
                                      className="me-2"
                                    />
                                    Date: {item.duration_type}
                                  </div>

                                  {item.access_module_features &&
                                    item.access_module_features
                                      .is_feature_checkins && (
                                      <div class="col-md-4 mb-2">
                                        <Icon.UiChecksGrid
                                          size={15}
                                          className="me-2"
                                        />
                                        {item.checkins_maximum} checkins
                                      </div>
                                    )}
                                  {item.access_module_features &&
                                    item.access_module_features
                                      .is_feature_invitions && (
                                      <div class="col-md-4 mb-2">
                                        <Icon.EnvelopePlusFill
                                          size={15}
                                          className="me-2"
                                        />
                                        {item.invitation_maximum
                                          ? item.invitation_maximum
                                          : "Unlimited"}{" "}
                                        Invitations
                                      </div>
                                    )}
                                  {item.access_module_features &&
                                    item.access_module_features
                                      .is_feature_form && (
                                      <div class="col-md-4 mb-2">
                                        <Icon.List size={15} className="me-2" />
                                        {item.form_maximum
                                          ? item.form_maximum
                                          : "Unlimited"}{" "}
                                        Forms
                                      </div>
                                    )}
                                  {item.access_module_features &&
                                    item.access_module_features
                                      .is_feature_reader_app && (
                                      <div class="col-md-4 mb-2">
                                        <Icon.PhoneFill
                                          size={15}
                                          className="me-2"
                                        />
                                        {item.reader_app_maximum
                                          ? item.reader_app_maximum
                                          : "Unlimited"}{" "}
                                        Readers
                                      </div>
                                    )}
                                  {item.access_module_features &&
                                    item.access_module_features
                                      .is_feature_send_access_by_csv && (
                                      <div class="col-md-4 mb-2">
                                        <Icon.FiletypeCsv
                                          size={15}
                                          className="me-2"
                                        />
                                        Send Access By CSV
                                      </div>
                                    )}
                                  {item.business_access_module_quotas &&
                                    item.business_access_module_quotas
                                      .access_maximum && (
                                      <div class="col-md-4 mb-2">
                                        <Icon.TicketFill
                                          size={15}
                                          className="me-2"
                                        />
                                        {item.business_access_module_quotas &&
                                        item.business_access_module_quotas
                                          .access_maximum
                                          ? item.business_access_module_quotas
                                              .access_maximum
                                          : "Unlimited"}{" "}
                                        Access
                                      </div>
                                    )}
                                </div>
                        </div>
                      </Card.Body>
                      <Card.Footer className="d-flex justify-content-between align-items-center">
                         <div className="d-flex align-start">
                                                          <Link
                                                            to={
                                                              "/business/projects/accesses/create/" +
                                                              item.id +
                                                              "/" +
                                                              params.hash_id
                                                            }
                                                            className="btn btn-sm btn-primary me-1"
                                                          >
                                                            Send Access
                                                          </Link>
                                                          <Link
                                                            to={
                                                              "/business/projects/forms/list/" +
                                                              item.id +
                                                              "/" +
                                                              params.hash_id
                                                            }
                                                            className="btn btn-sm btn-primary me-1"
                                                          >
                                                            {" "}
                                                            Forms
                                                          </Link>
                                                        </div>
                        <Dropdown className="d-inline-block">
                          <Dropdown.Toggle
                            variant="link"
                            className="btn btn-sm btn-light"
                          >
                            <Icon.ThreeDotsVertical />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                                                              <Link
                                                                to={
                                                                  "/business/projects/access-modules/accesses/" +
                                                                  item.id +
                                                                  "/" +
                                                                  item.title +
                                                                  "/" +
                                                                  params.hash_id
                                                                }
                                                                disabled
                                                                className="dropdown-item"
                                                              >
                                                                Accesses
                                                              </Link>
                                                              <Link
                                                                to={
                                                                  "/business/projects/access-modules/access-logs/" +
                                                                  item.id +
                                                                  "/" +
                                                                  item.title +
                                                                  "/" +
                                                                  params.hash_id
                                                                }
                                                                className="dropdown-item"
                                                              >
                                                                Access Logs
                                                              </Link>
                                                              <Link
                                                                to={
                                                                  "/business/projects/access-modules/update/" +
                                                                  item.id +
                                                                  "/" +
                                                                  params.hash_id
                                                                }
                                                                disabled
                                                                className="dropdown-item"
                                                              >
                                                                Edit
                                                              </Link>
                                                            </Dropdown.Menu>
                        </Dropdown>
                      </Card.Footer>
                    </Card>
                  </Col>
                ))}
              </Row>
              <HelparPagination
                page={currentPage}
                pageCount={pageCount}
                onPageChange={handlePageChange}
              />
              <DeleteConfirmationDialog
                show={isDeleteDialogOpen}
                onHide={handleCloseDialog}
                onConfirm={() => handleConfirmDelete(accessId)}
                message="Are you sure you want to delete this Access Module?"
                isLoading={isDeleteLoading}
              />
            </>
          ) : (
            <Alert color="secondary" className="text-center">
              <h5 className="mb-3">{messageList}</h5>
            </Alert>
          )}
        </>
      )}
    </LayoutProjects>
  );
};

export default AccessModulesList;
