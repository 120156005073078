import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Image, Badge, Alert, Table } from "react-bootstrap";
import apiAccessInvitations from "../Apis/AccessInvitations";
 import LayoutProjects  from "../Layouts/LayoutProjects";
import PageLoading from "../Helpars/PageLoading";
import HelparPagination from "../Helpars/Pagination";
import HelparAlert from "../Helpars/Alert";
import DeleteConfirmationDialog from "../Helpars/DeleteConfirmationDialog";
import moment from "moment";
import { useAuth } from "../AuthenticationUtility/AuthContext";

const AccessInvitationsList = () => {
  const { logout } = useAuth();
  const params = useParams();
  const [accessesInvitationsList, setAccessesInvitationsList] = useState([]);
  const [statusList, setStatusList] = useState("");
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [messageList, setMessageList] = useState("");
  const [errors, setErrors] = useState("");
  const [isPageLoading, setPageLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); // Current page starts at 1
  const [pageCount, setPageCount] = useState(null); // Current page starts at 1
  const [recordsPerPage, setRecordsPerPage] = useState(null); // Current page starts at 1
  const itemsPerPage = 20; // Number of items to display per page
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [accessId, setAccessId] = useState(false);
  const [isDeleteLoading, setDeleteLoading] = useState(false);

  const handleDeleteClick = (id) => {
    setIsDeleteDialogOpen(true);
    setAccessId(id);
  };

  const handleConfirmDelete = async (id) => {
    setDeleteLoading(true);
    let response = await apiAccessInvitations.Delete(
      logout,
      id,
      params.hash_id
    );
    setStatus(response.status);
    setMessage(response.message);
    setErrors(response.errors);
    let pagecurrent = currentPage;
    if (pageCount > 1) {
      if (recordsPerPage === 1) {
        pagecurrent = currentPage - 1;
      }
    }
    handlePageChange(pagecurrent);
    setIsDeleteDialogOpen(false);
    setDeleteLoading(false);
  };

  const handleCloseDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    ListAccessInvitations(page);
  };
  useEffect(() => {
    ListAccessInvitations(1);
  }, []);
  const ListAccessInvitations = async (page) => {
    let accessParentId = params.access_parent_id
      ? params.access_parent_id
      : '';
    let response = await apiAccessInvitations.list(
      logout,
      params.hash_id,
      accessParentId,
      page,
      itemsPerPage
    );
    setAccessesInvitationsList(response.records);
    setStatusList(response.status);
    setMessageList(response.message);
    setPageCount(Math.ceil(response.total / itemsPerPage));
    setRecordsPerPage(response.records_per_page);
    setPageLoading(false);
  };
  function DateFormat(date) {
    return <>{moment(date).format("DD-MM-YYYY")}</>;
  }
  const LayoutPropsCurrentRootName = params.access_parent_id
    ? "Invitaions"
    : "Access Invitaions";
  const LayoutPropsRootName = params.access_parent_id && "Accesses";
  const LayoutPropsRootLink =
    params.access_parent_id &&
    "/business/projects/access-modules/list/" + params.hash_id;

  return (
    <LayoutProjects
      currentRootName={LayoutPropsCurrentRootName}
      rootName={LayoutPropsRootName}
      rootLink={LayoutPropsRootLink}
    >
      {isPageLoading ? (
        <PageLoading />
      ) : (
        <>
          {statusList === "success" ? (
            <>
              <HelparAlert
                status={status}
                message={message}
                errors={errors}
              ></HelparAlert>
              <Table responsive="sm" striped="columns">
                <thead>
                  <tr>
                    <th>Created by</th>
                    <th>Access parent name</th>
                    <th>Invitation Name</th>
                    <th>Starts at</th>
                    <th>Ends at</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {accessesInvitationsList &&
                    accessesInvitationsList.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {item.parent &&
                          item.parent.user &&
                          item.parent.user.avatar_url !== null ? (
                            <Image
                              src={item.parent && item.parent.user.avatar_url}
                              width={30}
                              height={30}
                              rounded
                            />
                          ) : (
                            <div
                              style={{ width: 30, height: 30 }}
                              className="d-flex bg-primary border-primary fs-2 text-white fw-bold  align-content-center align-items-center justify-content-center rounded  me-2 mb-3"
                            >
                              {item.parent && item.parent.name[0].toUpperCase()}
                            </div>
                          )}
                          {item.parent && item.parent.user && (
                            <span className="ms-1">
                              {item.parent.user.first_name}{" "}
                              {item.parent.user.last_name}
                            </span>
                          )}
                        </td>
                        <td>{item.parent ? item.parent.name : ""}</td>

                        <td>{item.name}</td>
                        <td>{DateFormat(item.starts_at)}</td>
                        <td> {DateFormat(item.ends_at)}</td>
                        <td>
                          {new Date(item.ends_at) <= new Date() ? (
                            <Badge bg="danger">Date Expired</Badge>
                          ) : item.checkins_maximum !== null &&
                            item.checkins_maximum <= item.checkins_count ? (
                            <Badge bg="danger">Check-ins Expired</Badge>
                          ) : (
                            <Badge bg="success">Date Exist</Badge>
                          )}
                        </td>
                        <td>
                          {" "}
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleDeleteClick(item.id)}
                          >
                            Delete
                          </Button>{" "}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <DeleteConfirmationDialog
                id={accessId}
                isLoading={isDeleteLoading}
                isOpen={isDeleteDialogOpen}
                onDelete={handleConfirmDelete}
                onClose={handleCloseDialog}
              />
              {/* Render the pagination component */}
              <HelparPagination
                pageCount={pageCount}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </>
          ) : (
            <Alert color="secondary" className="text-center">
              <h5 className="mb-3">{messageList}</h5>
            </Alert>
          )}
        </>
      )}
    </LayoutProjects>
  );
};
export default AccessInvitationsList;
