 

import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Image,
  Badge,
  Button,
  OverlayTrigger,
  Tooltip,
  Modal,
  Alert
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import ApiAccessInvitations from "../Apis/AccessInvitations";
import Loading from "../Helpars/Loading";
import moment from "moment";
import { QRCode } from "react-qrcode-logo";
import { useAuth } from "../AuthenticationUtility/AuthContext";
import * as Icon from "react-bootstrap-icons";
import Footer from "../Footer";

const AccessInvitationsStaticQrCode = (props) => {
  const { logout } = useAuth();
  const params = useParams();
  const [accessInvitationData, setAccessInvitationData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [statusMessage, setStatusMessage] = useState(""); // New state for status messages
  const [readerModalShow, setReaderModalShow] = React.useState(false);

  useEffect(() => {
    populateAccessInvitations();
  }, [props.access_module_id]);

  const populateAccessInvitations = async () => {
    const invitationResponce = await ApiAccessInvitations.GetByAccessHashId(
      logout,
      params.access_hash_id
    );

    if (invitationResponce.status === "no_have_data") {
      setStatusMessage("Invitation is deleted");
    } else {
      const data = invitationResponce.data;
      const now = moment();

      // Check if the invitation is not started or already ended
      if (now.isBefore(moment(data.starts_at))) {
        const startTime = moment(data.starts_at).format("MMMM Do YYYY, h:mm A");
        setStatusMessage(
          `Invitation is not started yet. It will start on ${startTime}.`
        );
      } else if (now.isAfter(moment(data.ends_at))) {
        setStatusMessage("Invitation has ended.");
      } else {
        setAccessInvitationData(data);
        const root = document.documentElement;
        root.style.setProperty("--bs-primary", data.project.theme_color);
      }
    }
    setLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <div
        className="d-flex justify-content-center align-items-center vh-100"
        style={{ minHeight: "100vh" }}
      >
        <Loading />
      </div>
      ) : statusMessage ? ( // Check if there is a status message
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Card
            className="text-center shadow-lg p-3 w-75 w-md-50"
            style={{ maxWidth: "500px" }} // Max width for larger screens
          >
            <Card.Body>
              <Alert
                variant={
                  statusMessage.includes("ended") ? "danger" : "warning"
                } // Red for ended, yellow for not started
                className="p-3"
              >
                <h4 className="fw-bold mb-3 fs-6 fs-md-5">
                  {statusMessage.includes("deleted") ? "Notice" : "Status"}
                </h4>
                <p className="mb-0 fs-6 fs-md-5">{statusMessage}</p>
              </Alert>
            </Card.Body>
          </Card>
        </div>
      ) : (
        <Card style={{ fontSize: 13 }}>
          <Card.Header className="bg-primary text-white m-2 rounded">
  <div className="d-flex justify-content-between align-items-center">
    {/* Logo and Project Name */}
    <div className="d-flex align-items-center">
      {accessInvitationData && accessInvitationData.project.logo_url ? (
        <Image
          src={accessInvitationData.project.logo_url}
          width={50}
          height={50}
          rounded
          className="me-2"
        />
      ) : (
        <div
          style={{ width: 50, height: 50 }}
          className="d-flex bg-light border-primary fs-2 text-primary fw-bold align-items-center justify-content-center rounded me-2"
        >
          {accessInvitationData &&
            accessInvitationData.project.name[0].toUpperCase()}
        </div>
      )}
      <span className="fw-bold text-white">
        {accessInvitationData && accessInvitationData.project.name}
      </span>
    </div>

    {/* Access Name */}
    <div className="justify-content-end text-end">
      <strong>Access Name</strong>
      <div>{accessInvitationData && accessInvitationData.name}</div>
    </div>
  </div>
</Card.Header>

          <Card.Body className="text-center">
           {/* ---------Access Date And Time-----------*/}
           <Row className="mb-4">
              <Col>
                <div>
                  <span>Start at</span>
                  <div class="d-flex justify-content-center">
                  <span className="fw-bold" style={{fontSize:40}}>
                      {moment(accessInvitationData.starts_at).date()}
                    </span>
                    <div
                      className="d-grid ms-1 align-content-center"
                      style={{ fontSize: 12 }}
                    >
                      <span>
                        {moment(accessInvitationData.starts_at).format("MMM")}
                      </span>
                      <span>
                        {moment(accessInvitationData.starts_at).year()}
                      </span>
                    </div>
                  </div>
                  <div className="border-top pt-1">
                    {moment(accessInvitationData.starts_at).format("h:mm A")}
                  </div>
                </div>
              </Col>
              <Col>
                <div className="text-center">
                  <span>End at</span>
                  <div class="d-flex justify-content-center">
                    <span className="fw-bold" style={{fontSize:40}}>
                      {moment(accessInvitationData.ends_at).date()}
                    </span>
                    <div
                      className="d-grid ms-1 align-content-center"
                      style={{ fontSize: 12 }}
                    >
                      <span>
                        {moment(accessInvitationData.ends_at).format("MMM")}{" "}
                      </span>
                      <span>{moment(accessInvitationData.ends_at).year()}</span>
                    </div>
                  </div>
                  <div className="border-top pt-1">
                    {moment(accessInvitationData.ends_at).format("h:mm A")}
                  </div>
                </div>
              </Col>
            </Row>
            {/* ---------End Access Date And Time-----------*/}
             {/* ---------Checkins-----------*/}
             <div className="d-flex ms-1 justify-content-center">
              <div className="mb-4 border">
                <Row className="border-bottom m-0 p-1">
                  <Col>
                    <div>
                      <span>Maximum</span>
                      <Badge bg="black" className="ms-2 fw-bold">
                        <span className="fw-bold fs-6">
                          {accessInvitationData.checkins_maximum
                            ? accessInvitationData.checkins_maximum
                            : 0}
                        </span>
                      </Badge>
                      <span className="ms-2">Checkins</span>
                    </div>
                  </Col>
                </Row>
                {/* <Row className="p-2">
                  <Col>
                    <div>Used</div>
                    <Badge bg="danger" className="fw-bold fs-6">
                      <div>
                        {accessInvitationData.checkins_count
                          ? accessInvitationData.checkins_count
                          : 0}
                      </div>
                    </Badge>
                    <div className="mt-1">Checkins</div>
                  </Col>
                  <Col>
                    <div>Remaining</div>
                    <Badge bg="success" className="fw-bold fs-6">
                      <div>
                        {accessInvitationData.checkins_maximum
                          ? accessInvitationData.checkins_maximum -
                            (accessInvitationData.checkins_count
                              ? accessInvitationData.checkins_count
                              : 0)
                          : 0}
                      </div>
                    </Badge>
                    <div className="mt-1">Checkins</div>
                  </Col>
                </Row> */}
              </div>
            </div>
            {/* ---------End Checkins-----------*/}
            <QRCode
              value={accessInvitationData.qr_static_string}
              size={160}
              logoHeight={20}
              eyeRadius={10}
              logoWidth={20}
              logoOpacity={3}
              logoImage="https://kntopass.blob.core.windows.net/public/logo-icon.svg"
            />
          </Card.Body>
          {/* <Card.Footer className="bg-Light text-white m-2 rounded">
            <div class=" d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <span className="me-1">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="button-tooltip-2">Readers</Tooltip>}
                  >
                    {({ ref, ...triggerHandler }) => (
                      <Button
                        variant="primary"
                        {...triggerHandler}
                        className="d-inline-flex align-items-center"
                        onClick={() => setReaderModalShow(true)}
                      >
                        <Icon.PhoneFill size={20} ref={ref} />
                      </Button>
                    )}
                  </OverlayTrigger>
                </span>
                <span className="me-1">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="button-tooltip-2">Location</Tooltip>}
                  >
                    {({ ref, ...triggerHandler }) => (
                      <Button
                        variant="primary"
                        {...triggerHandler}
                        className="d-inline-flex align-items-center"
                        disabled={true}
                      >
                        <Icon.PinMapFill size={20} ref={ref} />
                      </Button>
                    )}
                  </OverlayTrigger>
                </span>
              </div>

              <div className="justify-content-end"></div>
            </div>
          </Card.Footer> */}
        </Card>
      )}
       <Modal
        show={readerModalShow}
        onHide={() => setReaderModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-primary"
          >
            <Icon.PhoneFill size={25} /> Reraders
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* ---------Readers-----------*/}
          <div className="text-center">
            <h5>Access by </h5>
            {/*<Row className="mb-4 d-flex justify-content-center">*/}
            {/*    {props.reader_apps !== null && props.reader_apps.length > 0 ? props.reader_apps.map(item => (<Col key={item.id} lg={3} className="border p-2 m-1"><span>{item.name}</span></Col>)) :*/}
            {/*        accessModuleData && accessModuleData.reader_apps && accessModuleData.reader_apps.map(item => (<Col key={item.id} lg={3} className="border p-2 m-1"><span>{item.name}</span></Col>))}*/}
            {/*</Row>*/}
          </div>
          {/* ---------End Readers-----------*/}
        </Modal.Body>
      </Modal>

      <Footer />
    </>
  );
};

export default AccessInvitationsStaticQrCode;
