import React, { useState } from "react";
import { Card, Button, Form, Container } from "react-bootstrap";
import Loading from "../Helpars/Loading";

const FormPreview = (props) => {
  //const [isLoading, setLoading] = useState(false);
  return (
    <>
      {/* {isLoading ? (
        <Loading />
      ) : ( */}
        <Card style={{ fontSize: 13 }}>
          {props.cover_url && (
            <div className="bg-primary text-white m-2 rounded">
              <img
                src={props.cover_url}
                style={{ maxHeight: 200, width: "100%" }}
              />
            </div>
          )}
          <Container>
            <Card.Header className="bg-white text-center border-0">
              <h1 className="fs-4 text-primary">
                {props.title ? props.title : "Title -----------------"}
              </h1>
              <p className="m-0">
                {props.description
                  ? props.description
                  : "Description ----------------------------------------"}
              </p>
            </Card.Header>
            <Card.Body>
              <div className="bg-primary text-white p-2 rounded mb-3 fs-6">
                Enter your information to obtain a pass
              </div>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control placeholder="Enter name" required />
                <Form.Text className="text-muted">
                  Enter a maximum of 20 characters
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control placeholder="Enter email" required />
              </Form.Group>
            </Card.Body>
             
          </Container>
        </Card>
      {/* )} */}
    </>
  );
};
export default FormPreview;
