import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";

const CustomFieldsCreate = ({ customFieldData, setCustomFieldData }) => {
  const [fieldIndex, setFieldIndex] = useState(0);

  useEffect(() => {
    if (customFieldData && customFieldData.some((item) => !item.id)) {
      const updatedCustomField = customFieldData.map((item) => ({
        ...item,
        id: item.id || uuidv4(), // Assign unique ID if not already present
      }));
      setCustomFieldData(updatedCustomField);
      setFieldIndex(updatedCustomField.length);
    }
  }, []); // Run only once on mount

  const addTextBox = () => {
    setCustomFieldData([
      ...customFieldData,
      {
        id: uuidv4(),
        type: "input",
        label: "",
        value: "",
        is_required: false,
        data_type: "text",
      },
    ]);
  };

  const addDropdown = () => {
    setCustomFieldData([
      ...customFieldData,
      {
        id: uuidv4(),
        type: "dropdown",
        label: "",
        value: "",
        options: [""],
        is_required: false,
      },
    ]);
  };

  const addFileUpload = () => {
    setCustomFieldData([
      ...customFieldData,
      {
        id: uuidv4(),
        type: "file_upload",
        label: "New File Upload", // Default label
        value: null, // Initialize value as null or undefined
        data_type: "file", // Assign data_type explicitly
        is_required: false, // Default to not required
        options: null, // No options for file_upload
      },
    ]);
  };

  const handleFileChange = (id, file) => {
    const updatedFields = customFieldData.map((field) =>
      field.id === id ? { ...field, value: file } : field
    );
    setCustomFieldData(updatedFields);
  };

  const removeField = (id) => {
    const updatedFields = customFieldData.filter((field) => field.id !== id);
    setCustomFieldData(updatedFields);
  };

  const handleLabelChange = (id, value) => {
    const updatedFields = customFieldData.map((field) =>
      field.id === id ? { ...field, label: value } : field
    );
    setCustomFieldData(updatedFields);
  };

  const onChangeIsRequired = (id) => {
    const updatedFields = customFieldData.map((field) =>
      field.id === id ? { ...field, is_required: !field.is_required } : field
    );
    setCustomFieldData(updatedFields);
  };

  const addDropdownOption = (id) => {
    const updatedFields = customFieldData.map((field) =>
      field.id === id
        ? { ...field, options: [...field.options, ""] }
        : field
    );
    setCustomFieldData(updatedFields);
  };

  const removeDropdownOption = (id, optionIndex) => {
    const updatedFields = customFieldData.map((field) =>
      field.id === id
        ? {
            ...field,
            options: field.options.filter((_, index) => index !== optionIndex),
          }
        : field
    );
    setCustomFieldData(updatedFields);
  };

  return (
    <Row>
      <Col md={9}>
        <DragDropContext
          onDragEnd={(result) => {
            if (!result.destination) return;

            const reorderedFields = Array.from(customFieldData);
            const [movedField] = reorderedFields.splice(result.source.index, 1);
            reorderedFields.splice(result.destination.index, 0, movedField);

            setCustomFieldData(reorderedFields);
          }}
        >
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {customFieldData&&customFieldData.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <Row
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="shadow-sm p-3 mb-3"
                      >
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Label</Form.Label>
                            <Form.Control
                              type="text"
                              value={item.label}
                              onChange={(e) => handleLabelChange(item.id, e.target.value)}
                            />
                          </Form.Group>
                          {item.type === "file_upload" && (
                            <Form.Group className="mb-3">
                              <Form.Label>File Upload</Form.Label>
                              <Form.Control
                                type="file"
                                onChange={(e) => handleFileChange(item.id, e.target.files[0])}
                              />
                            </Form.Group>
                          )}
                          {item.type === "dropdown" && (
                            <Form.Group className="mb-3">
                              <Form.Label>Options</Form.Label>
                              {item.options.map((option, optIndex) => (
                                <Row key={optIndex} className="mb-2">
                                  <Col md={8}>
                                    <Form.Control
                                      type="text"
                                      value={option}
                                      onChange={(e) => {
                                        const updatedOptions = [...item.options];
                                        updatedOptions[optIndex] = e.target.value;
                                        const updatedFields = customFieldData.map((field) =>
                                          field.id === item.id
                                            ? { ...field, options: updatedOptions }
                                            : field
                                        );
                                        setCustomFieldData(updatedFields);
                                      }}
                                    />
                                  </Col>
                                  <Col md={4}>
                                    <Button
                                      variant="link"
                                      className="text-danger text-decoration-none p-0"
                                      onClick={() => removeDropdownOption(item.id, optIndex)}
                                    >
                                      <Icon.Trash size={15} />
                                    </Button>
                                  </Col>
                                </Row>
                              ))}
                              <Button
                                variant="link"
                                className="text-primary text-decoration-none"
                                onClick={() => addDropdownOption(item.id)}
                              >
                                Add option
                              </Button>
                            </Form.Group>
                          )}
                        </Col>
                        <Col>
                          <Form.Check
                            className="mb-3"
                            type="checkbox"
                            label="Is Required"
                            checked={item.is_required}
                            onChange={() => onChangeIsRequired(item.id)}
                          />
                          <Button
                            variant="link"
                            className="text-danger text-decoration-none p-0"
                            onClick={() => removeField(item.id)}
                          >
                            <Icon.Trash size={20} /> Remove
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Col>
      <Col md={3}>
        <Card className="shadow-sm">
          <Card.Body className="text-center">
            <div className="pb-3">
              <Button
                variant="link"
                className="text-primary text-decoration-none"
                onClick={addTextBox}
              >
                <Icon.FileEarmarkFontFill size={20} /> Text input
              </Button>
            </div>
            <div className="pb-3">
              <Button
                variant="link"
                className="text-primary text-decoration-none"
                onClick={addDropdown}
              >
                <Icon.CaretDownFill size={20} /> Dropdown
              </Button>
            </div>
            <div>
              <Button
                variant="link"
                className="text-primary text-decoration-none"
                onClick={addFileUpload}
              >
                <Icon.FileEarmarkFill size={20} /> File Upload
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default CustomFieldsCreate;
