import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Modal, Button, Stack } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment';

const ExportFiles = ({ fieldsName, data, getDataToExport, fileName }) => {

    const [modalShow, setModalShow] = React.useState(false);
    const [selectedFields, setSelectedFields] = useState(fieldsName);



    const onDragEnd = (result) => {
        if (!result.destination) return;

        const newFields = Array.from(selectedFields);
        const [removed] = newFields.splice(result.source.index, 1);
        newFields.splice(result.destination.index, 0, removed);

        setSelectedFields(newFields);
    };
    const deleteField = (id) => {
        const updatedFields = selectedFields.filter((field) => field.field !== id);
        setSelectedFields(updatedFields);
    };
    const formatJsonField = (jsonField) => {
        try {
            // Parse the input JSON string into an object
            const generalStructure = JSON.parse(jsonField);
    
            // Check if the parsed structure is an array and has elements
            if (Array.isArray(generalStructure) && generalStructure.length > 0) {
                // Map through the array and concatenate label and value
                return generalStructure
                    .map(item => `${item.label} ${item.value}`)
                    .join(' - '); // Join with a dash separator
            } else {
                return ''; // Return empty string if not an array or empty
            }
        } catch (error) {
            console.error('Error parsing JSON:', error);
            return ''; // Return empty string if parsing fails
        }
    };
    const exportToCSV = () => {
        const header = selectedFields.map(item => item.name).join(',') + '\n';

        const rows = data.map(item => {
            // Map each item's values based on field names
            const rowValues = selectedFields.map(field => {
                // Handle nested fields like 'user.first_name'
                const nestedFields = field.field.split('.');
                let value = item;
                for (const nestedField of nestedFields) {
                    if (field.data_type === 'datetime') {
                        if (field.format)
                            value = moment(value[nestedField]).format(field.format);
                        else
                            value = moment(value[nestedField]).format('DD/MM/YYYY-hh:mm');
                    }
                    else if (field.data_type === 'unix') {
                        if (field.format) {
                            value = moment.unix(value[nestedField]).format(field.format);
                        }
                        else {
                            value = moment.unix(value[nestedField]).format('DD-MM-YYYY hh:mm A');
                        }
                    }
                    
                    else if (field.data_type === 'json') {
                        value = formatJsonField(value[nestedField]);
                    }
                     
                    else {
                        if (value[nestedField] != null)
                            value = value[nestedField];
                        else
                            value = ''
                    }

                    if (value === undefined) break; // Handle undefined nested field
                }
                return value === undefined ? '' : value;
            });
            return rowValues.join(',');
        }).join('\n');


        const csvFile = header + rows;
        return csvFile;
    };

    const exportToExcel = () => {
        const fields = selectedFields.map(item => item.field)
        const rows = data.map(item => {
            // Map each item's values based on field names
            return fields.map(fieldName => item[fieldName]);
        });


        // Create a new workbook
        const workbook = XLSX.utils.book_new();
        // Convert data to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(rows);
        // Set column headers
        const fieldHeaders = selectedFields.map(field => field.name);
        worksheet['!cols'] = selectedFields.map(field => ({ wch: field.name.length }));
        XLSX.utils.sheet_add_aoa(worksheet, [fieldHeaders], { origin: 'A1' });

        // Process nested fields
        data.forEach((item, rowIndex) => {
            selectedFields.forEach((field, columnIndex) => {
                const nestedFields = field.field.split('.');
                let nestedValue = item;
                for (const nestedField of nestedFields) {
                    if (field.data_type === 'datetime') {
                        if (field.format)
                            nestedValue = moment(nestedValue[nestedField]).format(field.format);
                        else
                            nestedValue = moment(nestedValue[nestedField]).format('DD/MM/YYYY_hh:mm');
                    }
                    else if (field.data_type === 'unix') {
                        if (field.format) {
                            nestedValue = moment.unix(nestedValue[nestedField]).format(field.format);
                        }
                        else {
                            nestedValue = moment.unix(nestedValue[nestedField]).format('DD-MM-YYYY hh:mm A');
                        }
                    }  
                    else if (field.data_type === 'json') {
                        nestedValue = formatJsonField(nestedValue[nestedField]);
                    }
                    else {
                        if (nestedValue[nestedField] != null)
                            nestedValue = nestedValue[nestedField];
                        else
                            nestedValue = ''

                    }
                    if (nestedValue === undefined) break;
                }
                XLSX.utils.sheet_add_aoa(worksheet, [[nestedValue]], { origin: { r: rowIndex + 1, c: columnIndex } });
            });
        });

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        // Generate an Excel file
        XLSX.writeFile(workbook, fileName + '_' + moment().format("DD-MM-YYYY-HH-mm") + '.xlsx');
    };
    const resetFields = () => {
        setSelectedFields(fieldsName);
    };

    const modalExport = () => {
        getDataToExport();
        setModalShow(true)
    };
    return (
        <><Button variant="primary" onClick={modalExport}>
            <Icon.Download size={17} className="me-1" /> Export
        </Button>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <Icon.Download size={20} className="me-2" />  Export Files
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <h5>Manage and Drag and Drop Fields</h5>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable" direction="horizontal">
                                {(provided) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        className="draggable-list" // Apply the class for the list
                                    >
                                        {selectedFields.map((field, index) => (
                                            <Draggable key={field.field} draggableId={field.field} index={index}>
                                                {(provided) => (
                                                    <div
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                        className="draggable-item" // Apply the class for each item
                                                    >
                                                        {field.name}
                                                        <div className="btn-draggable-item ms-2">
                                                            <Button variant="light" onClick={() => deleteField(field.field)} size="sm" className="mt-1"><Icon.TrashFill size={15} className="text-danger" /></Button>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                    <Stack direction="horizontal" gap={3}>
                        <div className="p-2"> <Button variant="secondary" onClick={resetFields}>Reset</Button></div>
                        <div className="p-2  ms-auto"><CSVLink className="btn btn-dark" data={exportToCSV()} filename={fileName + '_' + moment().format("DD-MM-YYYY_HH-mm") + ".csv"}>
                            <Icon.FiletypeCsv size={20} />CSV
                        </CSVLink></div>
                        <div className="p-2 "><Button variant="dark" onClick={exportToExcel}><Icon.FiletypeXlsx size={20} className="me-2" />Excel</Button></div>

                    </Stack>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setModalShow(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ExportFiles;