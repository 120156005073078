import React from "react";
import { Form } from "react-bootstrap";

const CustomFieldsIndex = ({ custom_fields, setCustomFieldData }) => {
  const handleTextBoxValueChange = (index, value) => {
    const updatedFields = [...custom_fields];
    updatedFields[index].value = value;
    setCustomFieldData(updatedFields);
  };

  const handleDropdownChange = (index, value) => {
    const updatedFields = [...custom_fields];
    updatedFields[index].value = value;
    setCustomFieldData(updatedFields);
  };

  const handleFileChange = (index, file) => {
    const updatedFields = [...custom_fields];
    updatedFields[index].value = file; // Store file object
    updatedFields[index].data_type = "file"; // Ensure data_type is set for file_upload
    setCustomFieldData(updatedFields);
  };

  return (
    <>
      {custom_fields &&
        custom_fields.map((item, index) => {
          if (item.type === "input") {
            return (
              <Form.Group className="mb-3" key={index}>
                <Form.Label>{item.label}</Form.Label>
                {item.data_type === "textarea" ? (
                  <Form.Control
                    as="textarea"
                    value={item.value || ""}
                    placeholder={`Enter ${item.label}`}
                    required={item.is_required}
                    onChange={(e) =>
                      handleTextBoxValueChange(index, e.target.value)
                    }
                  />
                ) : (
                  <Form.Control
                    type={item.data_type}
                    value={item.value || ""}
                    placeholder={`Enter ${item.label}`}
                    required={item.is_required}
                    onChange={(e) =>
                      handleTextBoxValueChange(index, e.target.value)
                    }
                  />
                )}
                <Form.Control.Feedback type="invalid">
                  {item.label} is required
                </Form.Control.Feedback>
              </Form.Group>
            );
          } else if (item.type === "dropdown") {
            return (
              <Form.Group className="mb-3" key={index}>
                <Form.Label>{item.label}</Form.Label>
                <Form.Select
                  value={item.value || ""}
                  className="mb-3"
                  required={item.is_required}
                  onChange={(e) => handleDropdownChange(index, e.target.value)}
                >
                  <option value="">Select {item.label}</option>
                  {item.options &&
                    item.options.map((option, optIndex) => (
                      <option key={optIndex} value={option}>
                        {option}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {item.label} is required
                </Form.Control.Feedback>
              </Form.Group>
            );
          } else if (item.type === "file_upload") {
            return (
              <Form.Group className="mb-3" key={index}>
                <Form.Label>{item.label}</Form.Label>
                <Form.Control
                  type="file"
                  required={item.is_required}
                  onChange={(e) => handleFileChange(index, e.target.files[0])}
                />
                <Form.Control.Feedback type="invalid">
                  {item.label} is required
                </Form.Control.Feedback>
              </Form.Group>
            );
          } else {
            return null;
          }
        })}
    </>
  );
};

export default CustomFieldsIndex;
