import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import ProtectedRoute from "./ProtectedRoute";

import "./custom.css";
import { Helmet } from "react-helmet";
import { AuthProvider } from "./components/AuthenticationUtility/AuthContext";

const App = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>KNTO Pass</title>
        <link rel="canonical" href="https://kntopass.com" />
        <meta
          name="description"
          content="Our Access Control App is your key to managing and safeguarding your spaces and digital resources. With easy-to-use features, you can set precise access permissions and monitor user activity, all while boosting security."
        />
      </Helmet>
      <AuthProvider>
      <Router>
      <Routes>
    {AppRoutes.map((route, index) => {
        const { element, requireAuth, ...rest } = route;
        return <Route key={index} {...rest} element={requireAuth ? <ProtectedRoute element={element} />  : element}/>;
    })}
</Routes>
</Router>
</AuthProvider>
    </>
  );
};

export default App;
