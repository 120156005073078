import React, { useState, useEffect } from "react";
import apiGeneralStructures from "../Apis/GeneralStructures";
import { useParams } from "react-router-dom";
import { useAuth } from "../AuthenticationUtility/AuthContext";
import { Form, Row, Col } from "react-bootstrap";

const GeneralStructures = ({access_module_id,lastSelected, setLastSelected, setGeneralStructures}) => {
  const params = useParams();
  const { logout } = useAuth();

  const [parentStructures, setParentStructures] = useState(null); // Initial parent structures
  const [selectedStructures, setSelectedStructures] = useState([]); // Selection chain
  const [isLastSelected, setIsLastSelected] = useState(false); // Track the last selected ID

  // Fetch initial structures (parents) on mount
  useEffect(() => {
    fetchParentStructures();
  }, []);

  const fetchParentStructures = async () => {
    try {
      const response = await apiGeneralStructures.List(
        logout,
        params.hash_id,
        access_module_id
      );
      setParentStructures(response.records);
      setGeneralStructures(!!response.records); // Set the boolean flag based on data existence

    } catch (error) {
      console.error("Error fetching parent structures:", error);
      setGeneralStructures(false);  

    }
  };

  const fetchChildStructures = async (parentId) => {
    try {
      const response = await apiGeneralStructures.List(
        logout,
        params.hash_id,
        access_module_id,
        parentId
      );
      return response.records;
    } catch (error) {
      console.error("Error fetching child structures:", error);
      return [];
    }
  };

  const handleStructureSelect = async (parentId, index) => {
    if (parentId) {
      const childStructures = await fetchChildStructures(parentId);
      if (childStructures) {
        setSelectedStructures((prevSelected) => {
          const updated = [...prevSelected];
          updated[index] = { parentId, childStructures }; // Store parent and its children
          updated.length = index + 1; // Remove deeper levels after this index
          return updated;
        });
      } else {
        setIsLastSelected(true);
        setLastSelected(parentId); // Update last selected value
      }
    } else {
      setSelectedStructures((prevSelected) => {
        const updated = [...prevSelected];
        updated.length = index; // Remove deeper levels after this index
        return updated;
      });
      setIsLastSelected(false);
      setLastSelected(null);
    }
  };

  const handleChildSelect = async (childId, index) => {
    await handleStructureSelect(childId, index + 1);
  };

  return (
    <div>
      <Row>
        <Col md={4}>
          {parentStructures && (
            <Form.Group className="mb-3">
              <Form.Label>{parentStructures?.type}</Form.Label>
              <Form.Select
                aria-label="Parent selection"
                onChange={(e) => handleStructureSelect(e.target.value, 0)}
                className="mb-3" required
              >
                <option value="">Select a {parentStructures?.type}</option>
                {parentStructures.general_structures?.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                  {parentStructures?.type} is required.
        </Form.Control.Feedback>
            </Form.Group>
          )}
        </Col>

        {selectedStructures.map(
          (selection, index) =>
            selection && (
              <Col md={4} key={index}>
                <Form.Group className="mb-3">
                  <Form.Label>{selection?.childStructures?.type}</Form.Label>
                  <Form.Select
                    aria-label="Child selection"
                    onChange={(e) => handleChildSelect(e.target.value, index)}
                    className="mb-3" required
                  >
                    <option value="">
                      Select a {selection?.childStructures?.type}
                    </option>
                    {selection?.childStructures?.general_structures?.map(
                      (child) => (
                        <option key={child.id} value={child.id}>
                          {child.name}
                        </option>
                      )
                    )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                  {selection?.childStructures?.type} is required.
        </Form.Control.Feedback>
                </Form.Group>
              </Col>
            )
        )}
      </Row>
       
    </div>
  );
};

export default GeneralStructures;
