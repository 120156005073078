import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Card, Row, Col, Alert, Image } from "react-bootstrap";
import apiProjectList from "../Apis/Projects";
import PageLoading from "../Helpars/PageLoading";
import { useAuth } from "../AuthenticationUtility/AuthContext";

const ProjectList = () => {
  const { logout } = useAuth();
  const params = useParams();

  const [businessList, setProjectsList] = useState([]);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [isPageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    ListProjects();
  }, [params.bussines_id]); // Add dependency if the business ID changes.

  const ListProjects = async () => {
    if (!isPageLoading) return; // Prevent duplicate API calls.
    try {
      let response = await apiProjectList.list(logout, params.bussines_id);
      setProjectsList(response.records);
      setStatus(response.status);
      setMessage(response.message);
    } catch (error) {
      setMessage("An error occurred while fetching projects.");
      setStatus("error");
    } finally {
      setPageLoading(false);
    }
  };

  return (
    <>
      {isPageLoading ? (
        <PageLoading />
      ) : (
        <Container fluid="md" className="p-0">
          {status === "success" ? (
            <Row>
              {businessList.map((item) => (
                <Col md={4} className="mb-3" key={item.id}>
                  <Card className="shadow-sm" border="dark">
                    <Card.Body className="d-flex align-items-center flex-column py-9 px-5">
                      {item.logo_url ? (
                        <Image
                          src={item.logo_url}
                          width={60}
                          height={60}
                          rounded
                          className="mb-3"
                        />
                      ) : (
                        <div
                          style={{ width: 60, height: 60 }}
                          className="d-flex bg-primary border-primary fs-5 text-white fw-bold rounded align-content-center align-items-center justify-content-center me-2 mb-3"
                        >
                          {item.name[0].toUpperCase()}
                        </div>
                      )}
                      <Card.Subtitle className="fs-4 text-gray-800 text-hover-primary mb-3">
                        {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                      </Card.Subtitle>
                      <Link
                        to={`/business/projects/${item.id}`}
                        className="btn btn-sm btn-primary"
                      >
                        Go to project
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          ) : (
            <Alert color="secondary" className="text-center">
              <h5 className="mb-3">{message}</h5>
              <Link to="/projects/projects/create" className="btn btn-sm btn-light">
                Create New Project
              </Link>
            </Alert>
          )}
        </Container>
      )}
    </>
  );
};

export default ProjectList;
