import ApiBase from './Base'

const url = "api/accesses/invitations/"

export default {
    async list(logout,hash_id, access_parent_id, page, per) {
        try {
            const response = await ApiBase.apiBase(url + 'list/' + hash_id + '?access_parent_id=' + access_parent_id + '&page=' + page + '&per=' + per, 'GET',logout)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async GetByAccessHashId(logout,access_hash_id) {
        try {
            const response = await ApiBase.apiBase(url + 'get_by_hash_id/' + access_hash_id , 'GET'.logout)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async Delete(logout,id, hash_id) {
        try {
            const urls = url + 'delete/' + id + '/' + hash_id;
            const response = await ApiBase.apiBase(urls, 'DELETE',logout)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    }
}
